import styled from "../styling/styled";
import { media } from "../styling/theme";

export enum IconType {
  Facebook = "Facebook",
  External = "External",
  ArrowDown = "ArrowDown",
  Phone = "Phone",
  Email = "Email"
}

const getSVG = (icon: IconType) => {
  switch (icon) {
    case IconType.Facebook:
      return (
        /* tslint:disable */
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="500"
          viewBox="0 0 500 500"
        >
          <path
            fill="#000952"
            d="M250,0 C111.928571,0 0,112.619048 0,251.52381 C0,377.071429 91.4285714,481.142857 210.952381,500 L210.952381,324.238095 L147.428571,324.238095 L147.428571,251.52381 L210.952381,251.52381 L210.952381,196.119048 C210.952381,133.071429 248.285714,98.2619048 305.380952,98.2619048 C332.761905,98.2619048 361.333333,103.166667 361.333333,103.166667 L361.333333,165.071429 L329.833333,165.071429 C298.761905,165.071429 289.095238,184.452381 289.095238,204.357143 L289.095238,251.52381 L358.428571,251.52381 L347.333333,324.238095 L289.095238,324.238095 L289.095238,500 C408.571429,481.142857 500,377.071429 500,251.52381 C500,112.619048 388.071429,0 250,0 Z"
          />
        </svg>
      );
    case IconType.External:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="500"
          viewBox="0 0 500 500"
        >
          <path
            fill="#B5B5B5"
            d="M423.371339,275.581142 L423.371339,428.78815 C423.371339,468.079477 391.413282,500.037534 352.121955,500.037534 L71.2493839,500.037534 C31.9163907,500.037534 0,468.079477 0,428.78815 L0,147.915579 C0,108.624252 31.958057,76.6661949 71.2493839,76.6661949 L224.456392,76.6661949 C235.605544,76.6661949 244.643718,85.7043684 244.643718,96.8535203 C244.643718,108.002672 235.605544,117.040846 224.456392,117.040846 L71.2493839,117.040846 C54.2397529,117.086632 40.4621038,130.864282 40.4163172,147.873913 L40.4163172,428.78815 C40.4163172,445.788003 54.2495309,459.621217 71.2493839,459.621217 L352.080289,459.621217 C369.163475,459.621217 382.996688,445.788003 382.996688,428.78815 L382.996688,275.581142 C382.996688,264.420484 392.044189,255.372983 403.204847,255.372983 C414.365505,255.372983 423.413005,264.420484 423.413005,275.581142 L423.371339,275.581142 Z M499.995677,173.373692 C499.995677,184.53435 490.948176,193.581851 479.787518,193.581851 C468.62686,193.581851 459.579359,184.53435 459.579359,173.373692 L459.579359,68.9995945 L213.20649,315.372464 C209.433046,319.194526 204.28589,321.345872 198.914947,321.345872 C193.544003,321.345872 188.396848,319.194526 184.623404,315.372464 C176.756416,307.484965 176.756416,294.718544 184.623404,286.831044 L431.03794,40.4165084 L326.580509,40.4165084 C315.419852,40.4165084 306.372351,31.3690076 306.372351,20.2083498 C306.372351,9.04769194 315.419852,0.000191159505 326.580509,0.000191159505 L479.787518,0.000191159505 C485.212373,-0.0234361134 490.417011,2.14406449 494.221493,6.01130162 C498.025976,9.87853874 500.108045,15.1179374 499.995677,20.5416802 L499.995677,173.373692 Z"
          />
        </svg>
      );
    case IconType.Phone:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="500"
          viewBox="0 0 500 500"
        >
          <polygon
            points="82.7 115.15 159.56 213.36 201.4 168.1 200.55 145.04 127.11 80.14 106.61 86.97 82.7 115.15"
            fill="#cd1626"
          />
          <polygon
            points="285.1 338.05 379.04 420.88 413.2 388.43 413.2 359.4 349.15 297.91 326.09 297.91 285.1 338.05"
            fill="#cd1626"
          />
          <path d="M367.47,298.34c-8.28-8.63-18.27-13.24-28.86-13.24S318,289.63,309.4,298.25l-27,26.91c-2.22-1.2-4.44-2.31-6.57-3.42-3.08-1.54-6-3-8.46-4.53-25.28-16-48.25-37-70.28-64-10.68-13.49-17.85-24.85-23.06-36.38,7-6.4,13.49-13.06,19.81-19.47,2.39-2.39,4.78-4.87,7.17-7.26C219,172.12,219,148.89,201,131l-23.31-23.31c-2.65-2.65-5.38-5.38-7.94-8.11-5.13-5.3-10.51-10.76-16.06-15.89-8.28-8.2-18.19-12.55-28.61-12.55s-20.49,4.35-29,12.55l-.17.17-29,29.29a62.49,62.49,0,0,0-18.53,39.72c-2,24.93,5.29,48.16,10.93,63.36,13.83,37.32,34.5,71.91,65.33,109C162,369.82,207,405.09,258.41,429.94c19.65,9.31,45.86,20.33,75.16,22.21,1.79.08,3.67.17,5.38.17,19.73,0,36.29-7.09,49.27-21.18.09-.17.26-.26.35-.43a194.11,194.11,0,0,1,14.94-15.46c3.67-3.5,7.43-7.17,11.1-11,8.46-8.8,12.9-19.05,12.9-29.55S423,353.94,414.36,345.4Zm30.58,89.93c-.09,0-.09.08,0,0-3.34,3.58-6.75,6.83-10.42,10.42a223.85,223.85,0,0,0-16.49,17.08c-8.62,9.22-18.78,13.57-32.11,13.57-1.28,0-2.64,0-3.93-.08-25.36-1.62-48.93-11.53-66.61-20a379.37,379.37,0,0,1-126-98.73C113.32,275.45,93.85,243,81,208.16c-7.94-21.27-10.84-37.84-9.56-53.47a39.17,39.17,0,0,1,11.78-25.36l29.12-29.12c4.19-3.93,8.63-6.06,13-6.06,5.38,0,9.74,3.24,12.47,6l.26.26c5.21,4.87,10.16,9.91,15.37,15.29,2.65,2.73,5.38,5.46,8.11,8.28l23.32,23.32c9,9,9,17.42,0,26.47-2.48,2.48-4.87,4.95-7.35,7.34-7.17,7.35-14,14.18-21.43,20.84-.17.17-.34.26-.43.43-7.34,7.34-6,14.52-4.44,19.38l.26.77c6.06,14.69,14.6,28.53,27.58,45l.09.09c23.57,29,48.42,51.66,75.83,69a116.52,116.52,0,0,0,10.51,5.72c3.07,1.54,6,3,8.45,4.53.34.17.69.42,1,.6a18.49,18.49,0,0,0,8.45,2.13c7.09,0,11.53-4.44,13-5.89l29.21-29.21c2.9-2.9,7.51-6.4,12.9-6.4s9.65,3.33,12.29,6.23l.17.17L398,361.54C406.76,370.25,406.76,379.21,398.05,388.27Z" />
          <path
            d="M266.36,143.93a110,110,0,0,1,89.5,89.5A11.46,11.46,0,0,0,367.22,243a16.2,16.2,0,0,0,2-.17,11.54,11.54,0,0,0,9.48-13.32A132.91,132.91,0,0,0,270.37,121.22a11.58,11.58,0,0,0-13.32,9.39A11.4,11.4,0,0,0,266.36,143.93Z"
            fill="#000e7a"
          />
          <path
            d="M451.85,226.18A218.83,218.83,0,0,0,273.53,47.86a11.51,11.51,0,1,0-3.76,22.72A195.47,195.47,0,0,1,429.13,229.93a11.46,11.46,0,0,0,11.36,9.57,16.2,16.2,0,0,0,2-.17A11.32,11.32,0,0,0,451.85,226.18Z"
            fill="#000e7a"
          />
        </svg>
      );
    case IconType.Email:
      return (
        <svg id="Vrstva_2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 437.23 265.7"><defs><clipPath id="clippath"><path d="M436.92 22.37C436.89 10.02 426.88.02 414.53 0L22.37.54C10 .56-.02 10.6 0 22.97s.31 220.36.31 220.36c.02 12.36 10.03 22.36 22.39 22.37l392.16-.54c12.36-.03 22.37-10.07 22.37-22.43l-.31-220.36Zm-47 .06L218.6 141.52 46.98 22.91l342.94-.48ZM291.06 164l101.6 78.79-348.41.48L146.47 164c4.97-3.68 6.01-10.7 2.33-15.67-3.68-4.97-10.7-6.01-15.67-2.33-.13.1-.26.19-.38.29L22.66 231.67l-.28-198.53 189.89 131.22c3.84 2.67 8.92 2.67 12.76 0L414.54 32.6l.29 199-110-85.34c-4.81-3.89-11.86-3.15-15.75 1.65-3.89 4.81-3.15 11.86 1.65 15.75.12.1.25.2.38.29l-.05.04Z" fill="none"/></clipPath></defs><g id="Vrstva_1-2"><g id="Vrstva_3"><path fill="#01afdd" d="M22.38 11.94h395.29L219.21 151.02 22.38 11.94z"/><path d="M436.92 22.37C436.89 10.02 426.88.02 414.53 0L22.37.54C10 .56-.02 10.6 0 22.97s.31 220.36.31 220.36c.02 12.36 10.03 22.36 22.39 22.37l392.16-.54c12.36-.03 22.37-10.07 22.37-22.43l-.31-220.36Zm-47 .06L218.6 141.52 46.98 22.91l342.94-.48ZM291.06 164l101.6 78.79-348.41.48L146.47 164c4.97-3.68 6.01-10.7 2.33-15.67-3.68-4.97-10.7-6.01-15.67-2.33-.13.1-.26.19-.38.29L22.66 231.67l-.28-198.53 189.89 131.22c3.84 2.67 8.92 2.67 12.76 0L414.54 32.6l.29 199-110-85.34c-4.81-3.89-11.86-3.15-15.75 1.65-3.89 4.81-3.15 11.86 1.65 15.75.12.1.25.2.38.29l-.05.04Z"/><g clip-path="url(#clippath)"><path d="M22.85 135.6h132.37v107.61H22.85zM282.39 135.6h132.37v107.61H282.39z"/></g></g></g></svg>
      );
    case IconType.ArrowDown:
      return (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="500"
          height="500"
          viewBox="0 0 500 500"
        >
          <polygon
            fill="#000E7A"
            points="480 0 240 259.94 .08 0"
            transform="translate(0 120)"
          />
        </svg>
        /* tslint:enable */
      );
    default:
      return null;
  }
};

interface IconWrapperProps {
  width?: string | number;
  height?: string | number;
  mobileWidth?: string | number;
  mobileHeight?: string | number;
}

const IconWrapper = styled.div<IconWrapperProps>(
  ({ width, height, mobileWidth, mobileHeight }) => ({
    "& svg": {
      display: "block",
      width: width ? `${width}px` : "auto",
      height: height ? `${height}px` : "auto"
    },
    [media.maxS]: {
      "& svg": {
        width: mobileWidth ? `${mobileWidth}px` : `${width}px`,
        height: mobileHeight ? `${mobileHeight}px` : `${height}px`
      }
    }
  })
);

interface IconProps {
  icon: IconType;
  width?: number;
  height?: number;
  mobileWidth?: string | number;
  mobileHeight?: string | number;
}

const Icon = ({
  icon,
  width,
  height,
  mobileWidth,
  mobileHeight
}: IconProps) => (
  <IconWrapper
    width={width}
    height={height}
    mobileWidth={mobileWidth}
    mobileHeight={mobileHeight}
  >
    {getSVG(icon)}
  </IconWrapper>
);

export default Icon;
